import { TimedRenderer, TimeUtil } from 'cuenect-web-core'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentContainer, LiveStreamContainerWebcast2 } from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig, isPostEvent } from '../config'

const HIDE_CHAT = '2021-10-06T15:00Z'

const getHideChat = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(HIDE_CHAT)
}

const ProgramPage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params
  const { currentWebinar } = eventConfig

  const noFrame =
    new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('noFrame') === 'true'

  const { t } = useTranslation('program')
  const [loaded, setLoaded] = React.useState(false)

  const currentSlug = `agenda-si-${lang}`

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame
      {...params}
      noFrame={noFrame}
      pageName="program"
      perectScroll={false}
    >
      {loaded && (
        <>
          <ContentContainer>
            <TimedRenderer
              startUtc="2021-10-19T22:00Z"
              endUtc="2021-10-20Z15:00Z"
            >
              <LiveStreamContainerWebcast2
                source={eventConfig.sources[lang]}
                slug={currentSlug}
              />
            </TimedRenderer>

            <ProgramContainer
              source={eventConfig.sources[lang]}
              subdivision={'stage'}
              slug={currentSlug}
              postEvent={isPostEvent()}
            />
          </ContentContainer>
        </>
      )}
    </PageFrame>
  )
}

export default ProgramPage
